<template>
  <div id="contents-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link class="btn btn-primary mb-5 mr-3" to="/contents/add">Externen Content hinzufügen</router-link>
    <button class="btn btn-secondary mb-5" disabled="disabled">Social Media Konten verbinden (bald)</button>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Google Analytics and Google Ads integration</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/personas">Personas</router-link></li>
      <li>Connect to <router-link to="/keywords">Keyword Planner</router-link></li>
    </ul>-->

    <IntroBanner v-if="!(map.nodes && map.nodes.length)" link="/contents/add" btn-text="Jetzt Content einpflegen"></IntroBanner>

    <div v-else>

      <NetworkGraph :links="map.links"
                    :nodes="map.nodes"
                    :selectedNode="selectedNode"
                    @selectNode="selectNode($event)"
                    :hide="true"
      >
      </NetworkGraph>

      <div class="charts row mt-4">
        <div v-for="(group, index) in groupViews" :key="index" class="col-6 col-md-3">
          <div class="card h-100">
            <div class="card-header chart-wrapper">
              <h4>Nach {{ group.title }}</h4>
              <div class="chart" @click="nodeView = group.prop">
                <PieChart :chart-data="chart[group.prop]" :options="options" class="mb-3"></PieChart>
              </div>
            </div>
            <div class="card-body">
              <ul class="list-unstyled">
                <li v-for="(group, index) of groupBy[group.prop]" :key="index" class="p-2">
                  <h5>{{ group.title }}</h5>
                  <ul class="list-unstyled piece-group">
                    <li v-for="(node, index) in group.nodes" :key="index" class="node-panel mt-3">
                      <div @click="selectNode(node)" class="card">
                        <div v-if="node.image.thumb" class="card-img-top">
                          <img :src="node.image.thumb" alt="" class="img-fluid">
                        </div>
                        <div class="card-body clickable">
                          <i class="fad fa-arrow-right text-primary"></i> {{ node.title }}
                        </div>
                        <div class="card-footer">
                          {{ node.platform }} / {{ newestUpdate(node) }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

import PieChart from "@/components/PieChart";
import NetworkGraph from "@/components/NetworkGraph";
import IntroBanner from "@/components/IntroBanner";

export default {
  name: 'Contents',
  components: {
    IntroBanner,
    NetworkGraph,
    PieChart,
  },
  data() {
    return {
      groupViews: [
        {prop: 'platform', title: 'Plattform'},
        {prop: 'intents', title: 'Intent'},
        {prop: 'topics', title: 'Thema'},
        {prop: 'category', title: 'Typ'},
      ],
      nodeView: 'platform',
      selectedNodeId: "",
      options: {
        responsive: true,
        maintainAspectRatio: true,
        backgroundColor: "#123456",
        cutoutPercentage: 50,
      },
      map: {}
    }
  },
  computed: {
    externalNodes() {
      return this.project.externalNodes.map(p => {
        p.image = p.image || {}
        return p;
      });
    },
    selectedNode() {
      return this.externalNodes.find(piece => piece.id === this.selectedNodeId);
    },
    groupBy() {
      return {
        platform: this.groupNodeByProp('platform'),
        intents: this.groupNodeByProp('intents'),
        topics: this.groupNodeByProp('topics'),
        category: this.groupNodeByProp('category'),
      }
    },
    chart() {
      return {
        platform: this.createChartData(this.groupBy['platform'], 'platform'),
        intents: this.createChartData(this.groupBy['intents'], 'intents'),
        topics: this.createChartData(this.groupBy['topics'], 'topics'),
        category: this.createChartData(this.groupBy['category'], 'category'),
      }
    },
  },
  methods: {
    saveNode() {
      this.$store.dispatch('project/updateProjectByProp', {prop: 'externalNodes', data: this.$store.getters.getProject.externalNodes});
    },
    removeNode(id) {
      let index = this.project.externalNodes.find(p => p.id === id);
      this.project.externalNodes.splice(index, 1);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'externalNodes', data: this.$store.getters.getProject.externalNodes});
    },
    selectNode(piece) {
      if (!piece.platform) return;
      this.selectedNodeId = piece.id;
      this.$router.push("/contents/" + piece.id);
    },
    newestUpdate(piece) {
      let updates = piece.timeline.updates;
      return updates.length ? updates[updates.length - 1].updatedAt.slice(0, 10) : null;
    },
    groupNodeByProp(prop) {
      return this.externalNodes.reduce((acc, node) => {
        if (typeof node[prop] === 'string') {
          if (!acc[node[prop]]) {
            acc[node[prop]] = {
              title: node[prop],
              nodes: []
            }
          }
          if (node[prop] === '') {
            if (!acc['Keine Auswahl']) {
              acc['Keine Auswahl'] = {title: "Keine Auswahl", nodes: []};
            }
            acc["Keine Auswahl"].nodes.push(node)
          } else {
            acc[node[prop]].nodes.push(node)
          }
        } else if (typeof node[prop] !== 'undefined') {
          if (node[prop].length) {
            for (let el of node[prop]) {
              if (!acc[el]) {
                acc[el] = {
                  title: el,
                  nodes: []
                }
              }
              acc[el].nodes.push(node);
            }
          } else {
            if (!acc['Keine Auswahl']) {
              acc['Keine Auswahl'] = {title: "Keine Auswahl", nodes: []};
            }
            acc["Keine Auswahl"].nodes.push(node)
          }
        }
        return acc;
      }, {});
    },
    createChartData(groupedData, prop) {
      let datasets = [
        {
          data: [],
          borderWidth: 0,
          hoverBorderWidth: 0,
        }
      ];
      let groupLength = Object.keys(groupedData).length;
      datasets[0].backgroundColor = Array.from({length: groupLength}).map((x, index) => {
        return this.pSBC(.5 - index / groupLength, this.project.settings.themeColor);
      });
      for (let group of Object.values(groupedData)) {
        datasets[0].data.push(group.nodes.length / this.externalNodes.length * 100)
      }
      if (prop === 'platform') {
        datasets[0].backgroundColor = Object.values(groupedData).map((group, index) => {
          return this.$store.state.platforms[group.title].color || datasets[0].backgroundColor[index];
        })
      }
      return {
        labels: Object.keys(groupedData),
        datasets
      }
    },
    addNode() {
      this.project.externalNodes.push({
        id: this.generateId(),
        title: "Titel",
        description: "Hier Beschreibung einfügen",
        url: "",
        embedCode: "",
        nodeUrls: [],
        state: "Conception",
        category: "Text",
        timeline: {
          createdAt: new Date(),
          updates: [
            {update: "", updatedAt: new Date()}
          ],
          deletedAt: null
        },
        topics: ["Apps"],
        tags: [],
        platform: "Facebook",
        links: [],
        ads: [
          {
            type: "",
            title: "",
            text: "",
            cost: 0.00,
            intent: ""
          }
        ],
        intents: [""],
      });
      this.$store.dispatch('project/updateProjectByProp', {prop: 'externalNodes', data: this.$store.getters.getProject.externalNodes})
    },
    setMap() {
      let externalNodes = this.project.externalNodes;
      let contentNodes = [];
      contentNodes = contentNodes.concat(externalNodes.map(node => {
        if (node.platform) {
          node._color = this.$store.state.platforms[node.platform].color;
        }
        node._cssClass = "depth-2";
        node.depth = 1;
        node.status = 200;
        for (let link of node.links) {
          let conNode = externalNodes.find(n => n.url === link);
          if (!conNode) {
            contentNodes.push({
              id: this.generateId(),
              url: link,
              title: link,
              depth: 2,
              _cssClass: "depth-3",
              _color: "#cccccc",
            });
          }
        }
        return node;
      }));

      let contentLinks = [];
      for (let node of contentNodes) {
        let linkedNodes = [];
        if (node.nodeUrls && node.nodeUrls.length) {
          linkedNodes = node.nodeUrls.map(url => {
            let conNode = contentNodes.find(n => n.url === url);
            if (!conNode) {
              conNode = this.project.netNodes.find(n => n.url === url);
            }
            if (!conNode) {
              conNode = {
                id: this.generateId(),
                url,
                title: url,
                depth: 1,
              }
              conNode._cssClass = "depth-1";
            }
            if (!contentNodes.find(n => n.url === url)) {
              contentNodes.push(conNode);
            }
            let link = {
              id: this.generateId(),
              sid: node.id,
              tid: conNode.id,
            };
            if (node.platform) {
              link._resetColor = this.$store.state.platforms[node.platform].color;
            }
            return link;
          });
        }
        if (node.links && node.links.length) {
          for (let link of node.links) {
            let conNode = externalNodes.find(n => n.url === link);
            if (conNode) {
              link = {
                id: this.generateId(),
                sid: node.id,
                tid: conNode.id
              };
            } else {
              link = {
                id: this.generateId(),
                sid: node.id,
                tid: contentNodes.find(p => p.title === link)
              };
            }
            if (node.platform) {
              link._resetColor = this.$store.state.platforms[node.platform].color;
            }
            linkedNodes.push(link);
          }
        }
        contentLinks = contentLinks.concat(linkedNodes);
      }

      this.map = {nodes: contentNodes, links: contentLinks};
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.selectedNodeId = this.$route.params.id;
    }
  },
  mounted() {
    new Promise(resolve => {
      let interval = setInterval(() => {
        if (!this.$store.state.dataRequestPending) {
          resolve();
          clearInterval(interval);
        }
      }, 100);
    })
        .then(() => {
          this.setMap();
        });
    this.$on("labels:update", data => {
      console.log("route labels:update", data);
    })
    this.$on("chart:update", data => {
      console.log("route chart:update", data);
    })
    this.$on("chart:render", data => {
      console.log("route chart:render", data);
    })
  }
}
</script>

<style lang="scss" scoped>
.chart {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 110%;
  cursor: pointer;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.piece-panel {
  cursor: pointer;
}
</style>