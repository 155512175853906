<script>
import {mixins, Pie} from 'vue-chartjs'

const {reactiveProp} = mixins

export default {
  name: 'PieChart',
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    options: Object
  },
  data() {
    return {}
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
}
</script>